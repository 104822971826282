import React, { Component } from 'react';
import { database, auth, storage } from '../../config/constants';
import { Spinner } from '../Spinner';
import '../../styles/Skill.css';

class Interest extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: {
				value1: '',
				value2: '',
			},
			isSubmitted: false,
			loading: true,
		};
		this.answerSelected = this.answerSelected.bind(this);
		this.questionSubmit = this.questionSubmit.bind(this);
	}

	answerSelected(event) {
		var value = this.state.value;
		if (event.target.name === 'value1') {
			value.value1 = event.target.value;
		} else if (event.target.name === 'value2') {
			value.value2 = event.target.value;
		}
		this.setState({ value: value }, function () {
			console.log(this.state);
		});
	}

	questionSubmit() {
		//Validate
		database.ref('users/' + auth.currentUser.uid + '/InterestTest/').set({
			values: [this.state.value.value1, this.state.value.value2],
		});
		this.props.history.push('/');
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		auth.onAuthStateChanged((currentUser) => {
			this.setState({ currentUser: currentUser || {} });

			if (currentUser) {
				// Init current user Refs
				// this.userRef = database.ref('/users').child(currentUser.uid);
				// this.skillRef = this.userRef.child('SkillSurvey');
				// this.userStorageRef = storage.ref('/user-files').child(currentUser.uid);
				// this.guidesRef.on('value', (snapshot) => {
				//   const guides = snapshot.val();
				//   this.setState({ guides });
				// });
				// this.userRef.child('images').on('value', (snapshot) => {
				//   const userImages = snapshot.val();
				//   if (userImages) {
				//     this.setState({ userImages });
				//   }
				// });
				// // Add user to users database if not exist
				// this.userRef.once('value', (snapshot) => {
				//   const userData = snapshot.val();
				//   if (!userData) {
				//     this.userRef.set({ name: currentUser.displayName });
				//   }
				// });
			} else {
				this.setState({ guides: null, userImages: null });
			}
		});
		database
			.ref('users/' + auth.currentUser.uid)
			.once('value')
			.then((snapshot) => {
				let data = snapshot.val();
				if (data && 'InterestTest' in data) {
					if ('values' in data['InterestTest']) {
						this.setState({
							...this.state,
							isSubmitted: true,
							loading: false,
						});
					} else {
						this.setState({
							...this.state,
							loading: false,
						});
					}
				} else {
					this.setState({
						...this.state,
						loading: false,
					});
				}
			});
	}

	render() {
		if (this.state.loading) {
			return <Spinner />;
		}

		var questions;
		var user = auth.currentUser;

		if (user && !this.state.isSubmitted) {
			questions = (
				<div className='App'>
					<div style={{ backgroundColor: '#2377BF' }}>
						<div className='container pt-3'>
							<h1 className='text-white fw-bold py-5'>
								Evaluate Interests!{' '}
							</h1>

							<p className='Hero-paragraph text-white pb-3'>
								Explore your personality, using scientifically crafted tools
							</p>
						</div>

						<div className='take-test-container container d-flex align-items-center pb-5'>
							<div>
								<h1 className='text-white fw-bold me-4 mb-0'>1.</h1>
							</div>
							<div>
								<a
									href='https://www.123test.com/career-test/'
									className='btn fw-bold'
									style={{ backgroundColor: '#FFCB77' }}
								>
									Take Test 123Test
								</a>
							</div>
						</div>
					</div>

					<div
						className='alert alert-primary sticky-top'
						role='alert'
					>
						<div className='container'>
							Input below the top two Interests obtained from the test above.
						</div>
					</div>

					<div
						className='py-5'
						style={{ backgroundColor: '#EBF5FF' }}
					>
						<div className='container'>
							<form
								className='row g-3 needs-validation'
								onSubmit={this.questionSubmit}
							>
								<div className='d-flex align-items-center'>
									<div>
										<h1
											className='fw-bold me-4'
											style={{ color: '#2377BF' }}
										>
											2.
										</h1>
									</div>
									<div>
										<p style={{ color: '#2377BF' }}>
											Input two Interests obtained from
											123Test.
										</p>
									</div>
								</div>
								<div className='col-lg-2 ms-5'>
									<label
										for='validationCustom04'
										className='form-label'
										style={{ color: '#2377BF' }}
									>
										Interest #1
									</label>
									<select
										name='value1'
										className='form-select'
										id='validationCustom04'
										defaultValue=''
										value={this.state.value1}
										onChange={this.answerSelected}
										required
									>
										<option disabled value=''>
											Choose...
											</option>
											<option value='Artistic' disabled={this.state.value.value2 == 'Artistic'}>
											Artistic
										</option>
										<option value='Conventional' disabled={this.state.value.value2 == 'Conventional'}>
											Conventional
										</option>
										<option value='Enterprising' disabled={this.state.value.value2 == 'Enterprising'}>
											Enterprising
										</option>
										<option value='Investigative' disabled={this.state.value.value2 == 'Investigative'}>
											Investigative
										</option>
										<option value='Realistic' disabled={this.state.value.value2 == 'Realistic'}>
											Realistic
										</option>
										<option value='Social' disabled={this.state.value.value2 == 'Social'}>Social</option>
									</select>
									<div className='invalid-feedback'>
										Please select a valid choice.
									</div>
								</div>

								<div className='col-lg-2'>
									<label
										for='validationCustom04'
										className='form-label'
										style={{ color: '#2377BF' }}
									>
										Interest #2
									</label>
									<select
										name='value2'
										className='form-select'
										id='validationCustom04'
										defaultValue=''
										value={this.state.value2}
										onChange={this.answerSelected}
										required
									>
																		<option disabled value=''>
											Choose...
											</option>
											<option value='Artistic' disabled={this.state.value.value1 == 'Artistic'}>
											Artistic
										</option>
										<option value='Conventional' disabled={this.state.value.value1 == 'Conventional'}>
											Conventional
										</option>
										<option value='Enterprising' disabled={this.state.value.value1 == 'Enterprising'}>
											Enterprising
										</option>
										<option value='Investigative' disabled={this.state.value.value1 == 'Investigative'}>
											Investigative
										</option>
										<option value='Realistic' disabled={this.state.value.value1 == 'Realistic'}>
											Realistic
										</option>
										<option value='Social' disabled={this.state.value.value1 == 'Social'}>Social</option>
									</select>
									<div className='invalid-feedback'>
										Please select a valid choice.
									</div>
								</div>

								<div className='col-12 ms-5 mt-5'>
									<button
										type='button'
										className='btn fw-bold'
										style={{ backgroundColor: '#FFCB77' }}
										onClick={this.questionSubmit}
									>
										Submit Answers
									</button>
								</div>
							</form>
						</div>
					</div>
					<div className='container'></div>
				</div>
			);
		} else if (this.state.isSubmitted) {
			return <h1>Already Submitted</h1>;
		} else {
			questions = (
				<div className='App'>
					<div style={{ backgroundColor: '#EAEAF7' }}>
						<div className='container'>
							<br />
							<br />
							<br />
							<br />
							<h1 className='Hero-text'>
								Sign in first, <br /> then I can help.
							</h1>{' '}
							<br />
							<br />
							<p className='Hero-paragraph'>
								You need to login to be able to find your values
								and save your progress.
							</p>
							<button
								className='Hero-button'
								onClick={this.signIn}
							>
								Sign In
							</button>
							<br />
							<br />
							<br />
							<br />
						</div>
					</div>
				</div>
			);
		}
		return <div>{questions}</div>;
	}
}

export default Interest;
