import React from 'react';
class DragColumn extends React.Component {
	initialState = {
		elementsA: [
			'Authenticity',
			'Achievement',
			'Adventure',
			'Authority',
			'Autonomy',
			'Balance',
			'Beauty',
			'Boldness',
			'Compassion',
			'Challenge',
			'Citizenship',
			'Community',
			'Competency',
			'Contribution',
			'Creativity',
			'Curiosity',
			'Determination',
			'Fairness',
			'Faith',
			'Fame',
			'Friendships',
			'Fun',
			'Growth',
			'Happiness',
			'Honesty',
			'Humour',
			'Influence',
			'Inner harmony',
			'Justice',
			'Kindness',
			'Knowledge',
			'Leadership',
			'Learning',
			'Love',
			'Loyalty',
			'Meaningful work',
			'Openness',
			'Optimism',
			'Peace',
			'Pleasure',
			'Poise',
			'Popularity',
			'Recognition',
			'Religion',
			'Reputation',
			'Respect',
			'Responsibility',
			'Security',
			'Self-respect',
			'Service',
			'Spirituality',
			'Stability',
			'Success',
			'Status',
			'Trust',
			'Wealth',
			'Wisdom',
		],
		elementsB: [],
		dragSource: null,
		dragTarget: null,
	};
	constructor(props) {
		super(props);
		this.init();
		this.state = this.initialState;
		this.onDrop = this.onDrop.bind(this);
		this.onDragStart = this.onDragStart.bind(this);
		this.onDragEnter = this.onDragEnter.bind(this);
		this.onDragOver = this.onDragOver.bind(this);
		this.onDragLeave = this.onDragLeave.bind(this);
		this.onDragExit = this.onDragExit.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.isDragSource = this.isDragSource.bind(this);
		this.isValidDragTarget = this.isValidDragTarget.bind(this);
		this.isDragTarget = this.isDragTarget.bind(this);
		this.moveElement = this.moveElement.bind(this);
		this.questionSubmit = this.questionSubmit.bind(this);
	}

	init() {
		require('../styles/DragAndDrop.css');
	}

	onDrop(e) {
		if (
			this.state.elementsB.length > '9' &&
			this.state.dragTarget === 'elementsB'
		) {
		} else if (this.isValidDragTarget(this.state.dragTarget)) {
			e.preventDefault();
			const elementId = e.dataTransfer.getData('text/plain');
			this.moveElement(elementId);
			this.props.onSelectLanguage([...this.state.elementsB, elementId]);
		}
	}

	moveElement(id) {
		const sourceElements = this.state[this.state.dragSource].filter(
			(e) => e !== id
		);
		this.setState((state) => ({
			[this.state.dragTarget]: [...state[this.state.dragTarget], id],
			[this.state.dragSource]: sourceElements,
			dragSource: null,
			dragTarget: null,
		}));
	}

	onDragStart(source) {
		this.setState({ dragSource: source });
	}

	onDragEnter(e, id) {
		if (this.isValidDragTarget(id)) {
			console.log('set target: ' + id);
			this.setState({ dragTarget: id });
		}
	}

	onDragOver(e, id) {
		if (this.isDragTarget(id)) {
			e.preventDefault(); // activate drop zone
		}
	}

	onDragLeave(e, id) {
		if (id === this.state.dragTarget) {
			this.setState({ dragTarget: null });
		}
	}

	onDragExit() {}

	onDragEnd() {
		this.setState({ dragSource: null, dragTarget: null });
	}

	isValidDragTarget(id) {
		return id !== this.state.dragSource;
	}

	isDragTarget(id) {
		return id === this.state.dragTarget && this.state.dragTarget !== null;
	}

	isDragSource(id) {
		return id === this.state.dragSource;
	}

	questionSubmit() {
		//Validate
		database.ref('users/' + auth.currentUser.uid + '/ValueTest/').set({
			value: this.state.value,
		});
		this.setState({ isSubmitted: true });
		this.props.history.push('/value');
	}

	render() {
		return (
			<>
				<div className='row mt-5 pt-3 m-0'>
					<div className='col-6 col-lg-3 me-0 me-lg-5'>
						<h3 className='text-color drag-box-heading fw-bold'>
							Unselected
						</h3>
					</div>

					<div className='col-6 col-lg-3 p-0'>
						<h3 className='text-color pb-1 drop-box-heading fw-bold'>Selected</h3>
						<p className='text-dark'>
							Select atleast 1 and upto 10 Values
						</p>
					</div>
				</div>
				<div className='row m-0'>
					<div className='col-6 col-lg-3 me-0 me-lg-5'>
						<div className='drag-box'>
							<DragAndDrop
								elements={this.state.elementsA}
								id={'elementsA'}
								onDrop={this.onDrop}
								onDragStart={this.onDragStart}
								onDragEnter={this.onDragEnter}
								onDragOver={this.onDragOver}
								onDragLeave={this.onDragLeave}
								onDragExit={this.onDragExit}
								onDragEnd={this.onDragEnd}
								isDragTarget={this.isDragTarget}
								isDragSource={this.isDragSource}
							/>
						</div>
					</div>
					<div className='col-6 col-lg-3 p-0'>
						<div className='drop-box'>
							<DragAndDrop
								elements={this.state.elementsB}
								id={'elementsB'}
								onDrop={this.onDrop}
								onDragStart={this.onDragStart}
								onDragEnter={this.onDragEnter}
								onDragOver={this.onDragOver}
								onDragLeave={this.onDragLeave}
								onDragExit={this.onDragExit}
								onDragEnd={this.onDragEnd}
								isDragTarget={this.isDragTarget}
								isDragSource={this.isDragSource}
							/>
						</div>
					</div>
					<div>
						{/* <pre>{JSON.stringify(this.state, null, 1)}</pre> */}
					</div>
				</div>
			</>
		);
	}
}

class DragAndDrop extends React.Component {
	constructor(props) {
		console.log('Creating component ' + props.id);
		super(props);
		this.onDragStart = this.onDragStart.bind(this);
		this.onDragEnd = this.onDragEnd.bind(this);
		this.onDragEnter = this.onDragEnter.bind(this);
		this.onDragLeave = this.onDragLeave.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.onDragOver = this.onDragOver.bind(this);
	}

	onDragOver(e) {
		this.props.onDragOver(e, this.props.id);
	}

	onDragEnter(e) {
		this.props.onDragEnter(e, this.props.id);
	}

	onDragLeave(e) {
		if (e.target.id === this.props.id) {
			this.props.onDragLeave(e, this.props.id);
		}
	}

	onDragExit(e) {}

	onDragStart(e) {
		this.props.onDragStart(this.props.id);
	}

	onDragEnd(e) {
		this.props.onDragEnd();
	}

	onDrop(e) {
		this.props.onDrop(e);
	}

	handleLangChange = () => {
		var results = this.state.elementsB;
		console.log(results);
		this.props.onSelectLanguage(results);
	};

	render() {
		const focused = this.props.isDragTarget(this.props.id)
			? 'drag-enter'
			: '';
		return (
			<div
				id={this.props.id}
				key={this.props.id}
				className={'drag-and-drop-wrapper ' + focused}
				onDragStart={this.onDragStart}
				onDragEnd={this.onDragEnd}
				onDragEnter={this.onDragEnter}
				onDragLeave={(e) => this.onDragLeave(e)}
				onDrop={this.onDrop}
				onDragOver={this.onDragOver}
				onChange={this.handleLangChange}
			>
				<ol className='px-3 px-lg-4 py-3'>
					{this.props.elements.map((e) => (
						<li className='dnd-item mb-3 text-center'>
							<DragAndDropElement key={e} element={e} />
						</li>
					))}
				</ol>
			</div>
		);
	}
}

const DragAndDropElement = ({ element }) => {
	const onDragStart = (e) => {
		e.dataTransfer.setData('text/plain', e.target.id);
		e.dropEffect = 'move';
	};
	return (
		<div
			key={element}
			id={element}
			className={''}
			draggable={true}
			onDragStart={onDragStart}
		>
			<Drag /> {element}
		</div>
	);
};

const Drag = () => {
	return <div className={'drag'}> </div>;
};

export default DragColumn;
