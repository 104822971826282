import React from "react";
import "../BarakaConcepts/BarakaConceptstyle.css";
import Image1 from "../../assets/images/steps1.png";
import Image2 from "../../assets/images/steps2.png";
import Image3 from "../../assets/images/steps3.png";
import Image4 from "../../assets/images/steps4.png";
import FancyWords from "../common/FancyWords";

const OurSteps = () => {
  return (
    <>
      <div
        style={{ backgroundColor: "#EFFFEE", color: "#4B5D7A" }}
        id="coachingProgram"
      >
        <div className="w-75 pt-5 pt-md-3 row mx-auto pb-md-5">
          <div className="col-lg-6 pt-md-5">
            <div className="d-flex align-items-center pt-md-3">
              <p style={{ fontSize: "1.2rem" }}>Our Steps</p>
            </div>

            <h1 className="fw-bold pb-3 pb-md-5">The coaching program</h1>

            <div className="d-flex pb-2">
              <h1
                className="pe-2 pe-md-4"
                style={{ fontSize: "4rem", fontFamily: "'Roboto', sans-serif" }}
              >
                1.
              </h1>
              <p className="w-75 fs-5">
                <span className="fw-bold">90-minute strengths &nbsp;</span>
                coaching session to uncover your top five talents.
              </p>
            </div>

            <div className="d-flex pb-2">
              <h1
                className=" pe-2 pe-md-4"
                style={{ fontSize: "4rem", fontFamily: "'Roboto', sans-serif" }}
              >
                2.
              </h1>
              <p className="w-75 fs-5">
                <span className="fw-bold">60-minute &nbsp;</span>
                session to
                <span className="fw-bold">
                  &nbsp; discover your values &nbsp;
                </span>{" "}
                and identify the skills you enjoy and those you should avoid.
              </p>
            </div>

            <div className="d-flex pb-2 mb-4">
              <h1
                className=" pe-2 pe-md-4"
                style={{ fontSize: "4rem", fontFamily: "'Roboto', sans-serif" }}
              >
                3.
              </h1>
              <p className="w-75 fs-5">
                <span className="fw-bold">60-minute &nbsp;</span>
                60-minute session to discuss your
                <span className="fw-bold">&nbsp; career interest &nbsp;</span>
                type and feasible best-fit career options
              </p>
            </div>

            <h1 className="fw-bold pt-md-3 pb-3 pb-md-4">This includes:</h1>
            <p className="w-75 fs-5 removingFixWidthOfP">
              4 assessments and three coaching sessions
            </p>
            <p className="w-75 fs-5 removingFixWidthOfP">
              A personalized career profile based on your strengths, values,
              skill, and interests, plus a unique contribution statement.
            </p>
          </div>

          <div className="col-lg-4 mx-auto mb-3 mb-md-0 pb-5 row g-2 g-md-5">
            <div className="col-5 mt-md-auto mb-4">
              <img className="img-fluid" src={Image1} alt="Steps Logo" />
            </div>
            <div className="col-5 mt-md-auto mb-4">
              <img
                className="img-fluid ms-3 ms-md-4"
                src={Image2}
                alt="Steps Logo"
              />
            </div>
            <div className="col-5 mt-md-5 pt-md-3">
              <img className="img-fluid" src={Image3} alt="Steps Logo" />
            </div>
            <div className="col-5 mt-md-5 pt-md-3">
              <img
                className="img-fluid ms-3 ms-md-4"
                src={Image4}
                alt="Steps Logo"
              />
            </div>
          </div>
        </div>

        <FancyWords />
      </div>
    </>
  );
};

export default OurSteps;
