import React from 'react';
import './FancyWords.css';

const FancyWords = () => {
  return (
    <div className="fancyParent" style={{width: "75%", margin: "auto"}}>
      <div className="fancyWords pt-4 pb-2 pb-md-4 row ">
      <div className="col-2 col-lg-3 firstOfFancy">
        <p>
          Strengths
        </p>
      </div>
      <div className="col-2 col-lg-3">
        <p>
          Values
        </p>
      </div>
      <div className="col-2 col-lg-3 ms-4 ms-md-0">
        <p>
          Skills
        </p>
      </div>
      <div className="col-2 col-lg-3  ms-4  ms-md-0">
        <p>
          Interests
        </p>
      </div>
    </div>
    </div>
  );
};

export default FancyWords;