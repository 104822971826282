import React from "react";
import "./BarakaConceptstyle.css";
import Image from "../../assets/images/bakaraConcept.png";
import { applyForBaraka } from "../../utils/Apply";

const BarakaConcepts = () => {
  // button name
  let btnName = "Apply";
  if (window.innerWidth >= 768) {
    btnName = "Apply for Aagahii";
  }

  const [emailMessage, setEmailMessage] = React.useState("");

  const apply = (event) => {
    setEmailMessage("");
    event.preventDefault();

    const emailRegex = /.+@.+\..+/;
    const email = document.getElementById("concepts-email-input");

    if (emailRegex.test(email.value)) {
      email.setCustomValidity("");
      const onFinish = () => {
        setEmailMessage(
          "Thank you for your interest in Aagahii. We will be in touch shortly."
        );
      };
      applyForBaraka(email.value, "", onFinish);
    } else {
      email.setCustomValidity("Please enter a valid email address");
      email.reportValidity();
    }
    return false;
  };

  return (
    <>
      <div
        style={{ backgroundColor: "#233B58", color: "#EAEAF7" }}
        id="barakaSection"
      >
        <div className="w-75 pt-5 pt-md-3 row mx-auto pb-md-5">
          <div className="col-lg-6 pt-md-5">
            <div className="d-flex align-items-center pt-md-3">
              <span className="hyphenStyle"></span>
              <p style={{ fontSize: "1.2rem" }}>
               Aagahii is a very powerful concept
              </p>
            </div>

            <h1 className="fw-bold pt-md-1 pb-3 pb-md-4">
             Aagahii is a Persian word for ‘awareness’ or ‘insight’.
            </h1>

            <div className="d-flex">
              <h1
                className="pe-2 pe-md-4"
                style={{ fontSize: "4rem", fontFamily: "'Roboto', sans-serif" }}
              >
                1.
              </h1>
              <p className="w-75 fs-5">
                <span className="fw-bold">
                 It signifies a knowledge and understanding of an object.”.&nbsp;
                </span>
                When applied to the self, it becomes ‘self-awareness’, a powerful concept and an indicator of success. 
              </p>
            </div>

            <div className="d-flex ">
              <h1
                className=" pe-2 pe-md-4"
                style={{ fontSize: "4rem", fontFamily: "'Roboto', sans-serif" }}
              >
                2.
              </h1>
              <p className="w-75 fs-5">
                <span className="fw-bold">
                 It is the skill of separating the person from environment and circumstance.&nbsp;
                </span>
              </p>
            </div>

            <div className="d-flex mb-4">
              <h1
                className=" pe-2 pe-md-4"
                style={{ fontSize: "4rem", fontFamily: "'Roboto', sans-serif" }}
              >
                3.
              </h1>
              <p className="w-75 fs-5">
                <span className="fw-bold">
                 It is a rare skill&nbsp;
                </span>
                even in those that believe themselves to possess it.
              </p>
            </div>

            <p>
              According to a Harvard study, even though most people believe they are self-aware, only 10-15% of the people studied actually fit the criteria.
              <a className="text-success" href="/">
                {" "}
                read more...
              </a>
            </p>

            <h5 className="pt-2 pb-3">
              We need our careers to not only grow continuously, but also be
              stable.
            </h5>

            <div
              style={{ width: "85%" }}
              className="input-group mt-md-4 mb-5 credentialsInput credentialsInputHeader"
            >
              <input
                type="text"
                id="concepts-email-input"
                className="form-control text-white"
                placeholder="Enter Email Address"
                aria-describedby="button-addon2"
                style={{
                  backgroundColor: "#0B2546",
                  border: "1px solid #0B2546",
                }}
              />
              <button
                className="btn btn-primary btn-md-lg btn-block rounded-end fw-bold"
                style={{ backgroundColor: "#00B48C", color: "#0B2546" }}
                onClick={(event) => apply(event)}
                id="button-addon2"
              >
                {btnName}
              </button>
            </div>
            <p
              style={{ fontSize: "1.2rem", fontStyle: "italic" }}
              className="pb-md-4"
            >
              {emailMessage}
            </p>
          </div>

          <div className="col-lg-4 mx-auto mt-md-5 pt-md-5 pb-5">
            <img
              className="img-fluid ms-md-5 mt-md-5 pt-md-5 ps-md-5 mb-md-5"
              src={Image}
              alt="Aagahii Ahead"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BarakaConcepts;
