import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.css";
import { Route, BrowserRouter, Link, Redirect, Switch } from "react-router-dom";
import Login from "./Login";
import Dashboard from "./protected/Dashboard";
import { firebaseAuth } from "../config/constants";
import Skill from "./protected/SkillPage";
import Interest from "./protected/InterestPage";
import Value from "./protected/ValuePage";
import Strength from "./protected/StrengthPage";
import Test from "./protected/TestPage";
import Result from "./protected/ResultPage";
import ReactLogo from "../assets/images/aagahi-logo.svg";
import Navbar from "./common/Navbar";
import Footer from "./common/Footer";
import { Spinner } from "./Spinner";
import "../styles/index.css";
import Main from "./Main";
import HomePage from "../components/HomePage/HomePage";
import "./Flex.css";

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === true ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component: Component, authed, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        authed === false ? (
          <Component {...props} />
        ) : (
          <Redirect to="/homepage" />
        )
      }
    />
  );
}

export default class App extends Component {
  state = {
    authed: false,
    loading: true,
  };
  componentDidMount() {
    this.removeListener = firebaseAuth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          authed: true,
          loading: false,
        });
      } else {
        this.setState({
          authed: false,
          loading: false,
        });
      }
    });
  }
  componentWillUnmount() {
    this.removeListener();
  }
  render() {
    return this.state.loading === true ? (
      <Spinner />
    ) : (
      <BrowserRouter>
        <div>
          <Navbar
            authState={this.state.authed}
            authChange={this.componentDidMount}
          />
        </div>

        <div>
          <div className="container-fluid p-0">
            <div>
              <Switch>
                <Route
                  path="/"
                  exact
                  render={() => {
                    return <Main authed={this.state.authed} />;
                  }}
                />
                <PublicRoute
                  authed={this.state.authed}
                  path="/login"
                  component={Login}
                />
                <Route path="/main" component={Main} />
                {/* <PublicRoute authed={this.state.authed} path='/register' component={Register} /> */}
                <PublicRoute
                  authed={this.state.authed}
                  path="/dashboard"
                  component={Dashboard}
                />

                <PrivateRoute
                  authed={this.state.authed}
                  path="/test"
                  component={Test}
                />
                <PrivateRoute
                  authed={this.state.authed}
                  path="/value"
                  component={Value}
                />
                <PrivateRoute
                  authed={this.state.authed}
                  path="/homepage"
                  component={HomePage}
                />
                <PrivateRoute
                  authed={this.state.authed}
                  path="/strength"
                  component={Strength}
                />
                <PrivateRoute
                  authed={this.state.authed}
                  path="/skill"
                  component={Skill}
                />
                <PrivateRoute
                  authed={this.state.authed}
                  path="/interest"
                  component={Interest}
                />
                <PrivateRoute
                  authed={this.state.authed}
                  path="/result"
                  component={Result}
                />
                <Route render={() => <h3>No Match</h3>} />
              </Switch>
            </div>
          </div>
        </div>

        <Footer authed={this.state.authed} />
      </BrowserRouter>
    );
  }
}
