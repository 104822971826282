import React from 'react';
import './HeaderTopMain.css';
import Image from "../../assets/images/topHeaderLog.png";
import OurMethods from './OurMethods';
import { applyForBaraka } from '../../utils/Apply';

const HeaderTop = () => {


  // button name
  let btnName = "Apply";
  if (window.innerWidth >= 768) {
    btnName = "Apply for Aagahii";
  }

  const [emailMessage, setEmailMessage] = React.useState('');

  const apply = (event) => {
    setEmailMessage('');
    event.preventDefault();

    const emailRegex = /.+@.+\..+/;
    const email = document.getElementById("header-email-input");
    
    if (emailRegex.test(email.value)) {
      email.setCustomValidity("");
      const onFinish = () => {setEmailMessage('Thank you for your interest in Aagahii. We will be in touch shortly.')};
      applyForBaraka(email.value, '', onFinish);
    }
    else {
      email.setCustomValidity("Please enter a valid email address");
      email.reportValidity();
    }
    return false;
  }

  return (
    <>
    <div className="pt-5" style={{backgroundColor: "#00B48C", color: "#fff"}}>
      <div className="w-75 row mx-auto pt-md-5 pb-md-5">
        <div className="col-lg-6 pt-md-5">

          <div className="d-flex align-items-center pt-md-3">
            <span className="hyphenStyle"></span>
            <p style={{fontSize: "1.2rem"}}>Welcome</p>
          </div>

          <h1 className="fw-bold pt-md-3 pb-md-4">
          Aagahii is a coaching program to help professionals uncover their uniqueness.
          </h1>

          <p style={{fontSize: "1.2rem"}} className="pb-md-4">Unleash your potential and find Aagahii in the road ahead.</p>
          <div style={{ width:"85%"}} className="input-group mb-5 credentialsInput credentialsInputHeader">
            <input
              type="text"
              id="header-email-input"
              className="form-control text-white"
              placeholder="Enter Email Address"
              aria-describedby="button-addon2"
              style={{backgroundColor: "#0E8B6F", border: "1px solid #0E8B6F"}}
              title="Please provide a valid e-mail address"
            />
            <button
              className="btn btn-primary btn-md-lg btn-block rounded-end text-black fw-bold"
              style={{ backgroundColor: "#FFCB77" }}
              id="button-addon2"
              onClick={(event) => apply(event)}
              value="Send Request"
            >
              {btnName}
            </button>
          </div>
          <p style={{fontSize: "1.2rem", fontStyle: "italic"}} className="pb-md-4">{emailMessage}</p>

        </div>

        <div className="col-lg-4 mx-auto  pt-md-5 pb-5">
          <img className="img-fluid ms-md-5 ps-md-5 mb-md-5" src={Image} alt="Aagahii" />
        </div>
      </div>
    </div>

    <OurMethods />

    </>
  );
};

export default HeaderTop;