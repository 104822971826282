import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { ReactComponent as FooterLogo } from "../../assets/images/footerLogo.svg";
import { HashLink } from "react-router-hash-link";
function Footer({ authed }) {
  // common design
  const footerLinks =
    "d-block text-decoration-none text-secondary my-4 footerLinks";
  return (
    <div className="footer-container mt-3 mt-md-5 pt-4 pb-3 mb-1">
      <div
        style={{ width: "90%" }}
        className=" footerMainContainer mx-auto row"
      >
        <div className="col-lg-5">
          <FooterLogo />
          <h4 className="w-75 pb-2 pb-md-5 fw-bold footerDesCrip">
            Helping individuals and businesses discover their core, and realise
            their potential.
          </h4>
          <p style={{ color: "#00B48C" }} className="fs-5 emailFooterDes">
            info@aagahii.com
          </p>

          <div className="instaClass me-4  d-inline">
            <i className="text-secondary fab fa-instagram" />
          </div>

          <div className="twitClass me-4 d-inline">
            <i className="text-muted fab fa-twitter" />
          </div>
          <div className="linkedClass me-4 d-inline">
            <i className=" text-muted fab fa-linkedin" />
          </div>
        </div>

        <div className="col-lg-6 d-md-flex justify-content-around">
          <div className="mt-5 mt-md-0">
            <h5>Home</h5>
            <HashLink
              to="/#whatBarakaDoesSection"
              smooth
              className={footerLinks}
            >
              What Aagahii does?
            </HashLink>
            <HashLink smooth to="/#coachingProgram" className={footerLinks}>
              Coaching Programme
            </HashLink>
            <HashLink smooth to="/#aboutSection" className={footerLinks}>
              About Me
            </HashLink>
            <HashLink smooth to="/#barakaSection" className={footerLinks}>
              Aagahii
            </HashLink>
            <HashLink smooth to="/#testimonialsSection" className={footerLinks}>
              Testimonials
            </HashLink>

            <Link to="/Login"><button className="btn btn-success btn--medium">Talk to Us</button></Link>
          </div>
          <div className="ms-md-3 mt-5 mb-5 mt-mb-0 mt-md-0">
            <h5>Evaluations</h5>
            <Link to="/strength" className={footerLinks}>
              Evaluate Strengths
            </Link>
            <Link to="/value" className={footerLinks}>
              Evaluate values
            </Link>
            <Link to="/skill" className={footerLinks}>
              Evaluate Skills
            </Link>
            <Link to="/interest" className={footerLinks}>
              Evaluate Interests
            </Link>
            <Link to="/result" className={footerLinks}>
              Results
            </Link>
            <Link to="/Login"><button className="btn btn--medium footerLoginBtn rounded-2">
              Login
            </button></Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
