import React, { Component } from "react";
import { login, resetPassword } from "../helpers/auth";

function setErrorMsg(error) {
  return {
    loginMessage: error,
  };
}

export default class Login extends Component {
  state = { loginMessage: null };
  handleSubmit = (e) => {
    e.preventDefault();
    login(this.email.value, this.pw.value).catch((error) => {
      this.setState(setErrorMsg("Invalid username/password."));
    });
  };
  resetPassword = () => {
    resetPassword(this.email.value)
      .then(() =>
        this.setState(
          setErrorMsg(`Password reset email sent to ${this.email.value}.`)
        )
      )
      .catch((error) => this.setState(setErrorMsg(`Email address not found.`)));
  };

  render() {
    let btnName = "Apply";
    if (window.innerWidth >= 768) {
      btnName = "Apply for Aagahii";
    }
    return (
      <>
        <div style={{ backgroundColor: "#00B48C" }}>
        <div className="py-4 pt-5 ">
        <h2
          className=" text-center pt-md-5 fw-bold fs-1"
          style={{ color: "#FFCB77" }}
        >
          {" "}
          Enter your Login Details{" "}
        </h2>
        <p
          className="text-center px-md-5 pb-3 pb-md-5 text-white"
          style={{ fontSize: "1.1rem" }}
        >
          Please enter your custom credentials sent to you over email for access to the Aagahii tools.
        </p>
          <form
            className="mx-auto shadow p-3 p-md-5 mb-5 rounded bg-white contactForm"
            style={{ width: "30vw" }}
            onSubmit={this.handleSubmit}
          >
            <div className="form-group">
            <label className="pt-3 pb-1 mx-1 fs-6">Email</label>
              <input
                type="email"
                className="form-control p-2 rounded-3"
                id="inputEmail"
                required
                ref={(email) => (this.email = email)}
                placeholder="Enter your Name"
              />
            </div>
            <div className="form-group">
            <label className="pt-3 pb-1 mx-1 fs-6">Password</label>
            <input
              type="password"
              className="form-control"
              placeholder="Password"
              ref={(pw) => (this.pw = pw)}
            />
          </div>
            
          {this.state.loginMessage && (
            <div className="alert alert-danger" role="alert">
              <span
                className="glyphicon glyphicon-exclamation-sign"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Error:</span>
              &nbsp;{this.state.loginMessage}{" "}
              <a
                href="#"
                onClick={this.resetPassword}
                className="alert-link"
              >
                Forgot Password?
              </a>
            </div>
          )}
            <button
              type="submit"
              className="btn btn-primary btn--medium btn-md-lg btn-block mt-4 rounded-3"
              style={{ width: "100%", backgroundColor: "#00B48C" }}
            >
              I want to be coached
            </button>
          </form>
        </div>
        </div>


        {/* crediantials */}
        <div className="p-5 pb-4" style={{ backgroundColor: "#233B58" }}>
          <p className="text-center pb-2 text-white fs-4 fw-bold">
            Don't have credentials?
          </p>
          <div
            style={{ width: "35%" }}
            className="input-group mb-5 mx-md-auto credentialsInput"
          >
            <input
              type="text"
              className="form-control text-white"
              placeholder="Enter your Email"
              aria-describedby="button-addon2"
              style={{
                backgroundColor: "#0B2546",
                border: "1px solid #0B2546",
              }}
            />
            <button
              className="btn btn-primary btn-md-lg btn-block rounded-end"
              style={{ backgroundColor: "#00B48C" }}
              type="button"
              id="button-addon2"
            >
              {btnName}
            </button>
          </div>
        </div>
      </>
    );
  }
}
