import React, { useEffect } from "react";
import Cover from "./Cover/Cover";
import Meaning from "./Meaning";
import HeaderTop from "./HeaderTopMain/HeaderTop";
import "./main.css";

import { ReactComponent as AbtHeart } from "../assets/images/abtMeHeart.svg";
import { ReactComponent as AbtPhn } from "../assets/images/abtMePhn.svg";
import { ReactComponent as AbtBag } from "../assets/images/abtMeBag.svg";
import CustomerTestimonals from "./CustomerTestmonials/CustomerTestimonals";
import BarakaConcepts from "./BarakaConcepts/BarakaConcepts";
import OurSteps from "./OurSteps/OurSteps";
import { applyForBaraka } from "../utils/Apply";

const Main = ({ authed }) => {
  const [emailMessage, setEmailMessage] = React.useState("");

  const apply = (event) => {
    setEmailMessage("");
    event.preventDefault();

    const emailRegex = /.+@.+\..+/;
    const email = document.getElementById("inputEmail");
    const name = document.getElementById("inputName");
    const message = document.getElementById("inputMessage");

    if (emailRegex.test(email.value)) {
      email.setCustomValidity("");
      const onFinish = () => {
        setEmailMessage(
          "Thank you for your interest in Aagahii. We will be in touch shortly."
        );
      };
      applyForBaraka(email.value, name.value + "||" + message.value, onFinish);
    } else {
      email.setCustomValidity("Please enter a valid email address");
      email.reportValidity();
    }
    return false;
  };

  return (
    <>
      <div>
        <div className="p-0">
          <HeaderTop />

          {/* our steps */}
          <OurSteps />

          {/* Baraka Concept */}
          <BarakaConcepts />

          {/* about Me */}
          <div
            className="w-100 pt-md-4 pb-md-4"
            style={{ backgroundColor: "#E5E5E5" }}
          >
            <div className="w-75 mx-auto row" id="aboutSection">
              <p
                className="pt-5 pb-md-4 fs-2 fw-bold"
                style={{ color: "#143656" }}
              >
                About Me
              </p>
              <div className="col-lg-6 ">
                <p className="pe-md-5 fs-5  text-secondary">
                  I am an empathic and creative people-developer who mentors,
                  and collaborates with, individuals and businesses to bring out
                  the best in them. I have over a decade of multi-industry
                  experience in private and social development sectors. In
                  addition to holding a Master’s degree, I am a certified YouMap
                  coach, an SHRM-certified human resources professional, a
                  CPPD-certified counselor, and a published poet.{" "}
                </p>
                <p className="pb-3 pb-md-5" style={{ color: "#2377BF" }}>
                  My journey is filled with self-discovery, pivots, serendipity,
                  mistakes and imperfect action.
                </p>
              </div>
              <div className="col-lg-6 px-md-5">
                <div className="d-flex justify-content-center mb-1">
                  <AbtHeart />
                  <p
                    className="w-50 ms-3 aboutMeRightSideClass"
                    style={{ color: "#00B48C", fontSize: "1.1rem" }}
                  >
                    I have a personal connection approach - offering
                    personalized solutions when possible
                  </p>
                </div>
                <div className="d-flex  justify-content-center  mb-1">
                  <AbtPhn />
                  <p
                    className="w-50 ms-3 aboutMeRightSideClass"
                    style={{ color: "#00B48C", fontSize: "1.1rem" }}
                  >
                    I am available through personal or online presence
                  </p>
                </div>
                <div className="d-flex align-items-center justify-content-center  mb-5">
                  <AbtBag />
                  <p
                    className="w-50 aboutMeRightSideClass ms-3 mt-2 "
                    style={{ color: "#00B48C", fontSize: "1.1rem" }}
                  >
                    I bring a structured approach into the work.
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* customer Testimonials */}
          <CustomerTestimonals />

          {/* Contact Form */}
          <div className="py-4 pt-5 main_contact_form">
            <h2 className="text-center fw-bold pb-4 mb-md-5 pb-md-4">
              Contact Us
            </h2>
            <form
              className="mx-auto shadow p-3 p-md-5 mb-5 rounded bg-white contactForm"
              style={{ width: "35vw" }}
            >
              <div className="form-group">
                <label
                  className="pt-3 pb-1 mx-1 fs-6"
                  htmlFor="exampleInputEmail1"
                >
                  Name
                </label>
                <input
                  type="email"
                  className="form-control p-2 rounded-3"
                  id="inputName"
                  required
                  aria-describedby="emailHelp"
                  placeholder="Enter your Name"
                />
              </div>
              <div className="form-group">
                <label
                  className="pt-3 pb-1 mx-1 fs-6"
                  htmlFor="exampleInputEmail1"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="form-control p-2 rounded-3"
                  id="inputEmail"
                  required
                  aria-describedby="emailHelp"
                  placeholder="Enter your Email"
                />
              </div>
              <div className="form-group">
                <label
                  className="pt-3 pb-1 mx-1 fs-6"
                  htmlFor="exampleInputEmail1"
                >
                  Message
                </label>
                <textarea
                  className="form-control rounded-3"
                  id="inputMessage"
                  required
                  style={{ resize: "none", height: "10rem" }}
                  aria-describedby="emailHelp"
                  placeholder="Enter your message on why you want to be coached?"
                />
              </div>

              <button
                onClick={(event) => apply(event)}
                className="btn btn-primary btn--medium btn-md-lg btn-block mt-4 rounded-3"
                style={{ width: "100%", backgroundColor: "#00B48C" }}
              >
                I want to be coached
              </button>
              <p
                style={{ fontSize: "1.2rem", fontStyle: "italic" }}
                className="pb-md-4"
              >
                {emailMessage}
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Main;
