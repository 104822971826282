import React, { Component } from 'react';
import { database, auth, storage } from '../../config/constants';

import strengthstext from '../../assets/content/strengths.json';
import skilllist from '../../assets/content/skills.json';
import interestslist from '../../assets/content/interests.json';

import '../../styles/Skill.css';
import { Spinner } from '../Spinner';

class Result extends Component {
	constructor(props) {
		super(props);

		this.state = { loading: true };
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		auth.onAuthStateChanged((currentUser) => {
			this.setState({ currentUser: currentUser || {} });
		});

		database
			.ref('users/' + auth.currentUser.uid)
			.once('value')
			.then((snapshot) => {
				let data = snapshot.val();
				let incomplete = false;
				if (
					!('StrengthTest' in data) ||
					!('values' in data['StrengthTest'])
				) {
					incomplete = true;
				}
				if (
					!('InterestTest' in data) ||
					!('values' in data['InterestTest'])
				) {
					incomplete = true;
				}
				if (
					!('SkillSurvey' in data) ||
					!('answers' in data['SkillSurvey'])
				) {
					incomplete = true;
				}
				if (
					!('ValueTest' in data) ||
					!('values' in data['ValueTest'])
				) {
					incomplete = true;
				}
				if (!incomplete) {
					this.setState({
						strengths: data['StrengthTest']['values'],
						interests: data['InterestTest']['values'],
						skills: data['SkillSurvey']['answers'],
						values: data['ValueTest']['values'],
						incomplete: false,
						loading: false,
					});
				} else {
					this.setState({
						incomplete: true,
						loading: false,
					});
				}
			});
	}

	render() {
		if (this.state.loading) {
			return <Spinner />;
		}
		if (this.state.incomplete) {
			return <h1>Fill Survey First</h1>;
		}

		let strengths = this.state['strengths'].map((strength) => (
			<div className='py-3'>
				<h2 className='fw-bold pb-4'>{strength}</h2>
				<p>{strengthstext[strength]}</p>
			</div>
		));

		let values = this.state['values'].map((value) => (
			<li className='list-item'>{value}</li>
		));

		let skills = Object.keys(this.state['skills']).map(
			(key) => this.state['skills'][key]
		);
		console.log(skills);
		let topskills = skilllist.map((skillgroup) => (
			<li className='pb-3' style={{breakInside: "avoid-column"}}>
				<h3 className='table-title'>{skillgroup[0]}</h3>
				{skillgroup[1].map((skill) => (
					<li
						className='table-list-item'
						style={{
							color:
								skills.indexOf(skill) != -1
									? '#00B48C'
									: 'black',
							listStyleType: 'none'
						}}
					>
						{skill}
					</li>
				))}
			</li>
		));
		// let leastskills = skilllist.map((skillgroup) => (
		// 	<div className='pb-3'>
		// 		<h3 className='table-title'>{skillgroup[0]}</h3>
		// 		<ul className='table-list'>
		// 			{skillgroup[1].map((skill) => (
		// 				<li
		// 					className='table-list-item'
		// 					style={{
		// 						color:
		// 							skills.indexOf(skill) != -1
		// 								? '#00B48C'
		// 								: 'black',
		// 					}}
		// 				>
		// 					{skill}
		// 				</li>
		// 			))}
		// 		</ul>
		// 	</div>
		// ));

		let interest =
			interestslist[
				interestslist.findIndex((interest) => {
					console.log(interest.name);
					return (
						this.state.interests &&
						interest.name.includes(this.state.interests[0]) &&
						interest.name.includes(this.state.interests[1])
					);
				})
			];

		return (
			<div className='strength-container'>
				<div
					className='strength-banner'
					style={{ backgroundColor: '#00B48C' }}
				>
					<div className='container'>
						<h1 className='text-white fw-bold pt-5'>
							Your Results
						</h1>
						<p className='text-white pb-5 pt-3 col-lg-3 mb-0'>
							Below is your Aagahii summary. Read it carefully, and discuss it with your coach or mentor. Plan your career accordingly. 
						</p>
					</div>
				</div>

				{/* Strengths part */}
				<div className='py-5' style={{ backgroundColor: '#FE8083' }}>
					<h1 className='container text-white fw-bold mb-0'>
						Your Strengths
					</h1>
				</div>

				<div
					className='strength-container pb-5'
					style={{ backgroundColor: '#FEE9EA' }}
				>
					<div
						className='container py-5'
						style={{ color: '#FE8083' }}
					>
						{strengths}
					</div>
				</div>

				{/* Values Part */}
				<div className='py-5' style={{ backgroundColor: '#FFCB77' }}>
					<h1 className='container text-white fw-bold mb-0'>
						Values
					</h1>
				</div>

				<div
					className='value-text-container'
					style={{ backgroundColor: '#FFF3CD' }}
				>
					<div
						className='container py-5'
						style={{ color: '#FFCB77' }}
					>
						<ol className='value-list'>{values}</ol>
					</div>
				</div>

				{/* Skill Part */}
				<div className='py-5' style={{ backgroundColor: '#00B48C' }}>
					<h1 className='container text-white fw-bold mb-0'>
						Your Skills
					</h1>
				</div>

				<div
					className='strength-container pb-5'
					style={{ backgroundColor: '#D4EDDA' }}
				>
					<div
						className='container py-5'
						style={{ color: '#D4EDDA' }}
					>
						<ul className='value-list'>{topskills}</ul>
					</div>
				</div>

				{/* Interest Part */}
				<div className='py-5' style={{ backgroundColor: '#2377BF' }}>
					<h1 className='container text-white fw-bold mb-0'>
						Your Interest
					</h1>
				</div>

				<div
					className='interest-text-container pb-3'
					style={{ backgroundColor: '#EBF5FF' }}
				>
					<div
						className='container py-5'
						style={{ color: '#2377BF' }}
					>
						<h3>{interest.name}</h3>
						<p>
							<strong>Descriptors: </strong>
							{interest.descriptors}
						</p>
						<p>{interest.picture}</p>
						<p>{interest.description}</p>
					</div>
				</div>
			</div>
		);
	}
}

export default Result;
