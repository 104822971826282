import React, { useState, useEffect } from "react";
import { Button } from "./Button";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./Navbar.css";
import { logout } from "../../helpers/auth";
import { HashLink } from "react-router-hash-link";
import HeaderLogo from "../../assets/images/aagahi-logo.svg";

function Navbar({ authState }) {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {};

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener("resize", showButton);

  let bgColor = "#00B48C";
  const location = useLocation();
  if (location.pathname === "/strength") {
    bgColor = "#FE8083";
  } else if (location.pathname === "/interest") {
    bgColor = "#2377BF";
  } else if (location.pathname === "/value") {
    bgColor = "#00B48C";
  }

  return (
    <div className="navbar-container" style={{ backgroundColor: `${bgColor}` }}>
      <nav className="navbar navbar-light navbar-expand-lg container pt-4">
        <div className="container-fluid p-0">
          <Link className="navbar-brand" to="/">
            <img
              src={HeaderLogo}
              alt="header-logo"
              className="img-fluid"
              height="150"
              width="150"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            style={{ backgroundColor: "#FFCB77", color: "#000" }}
          >
            <span className="navbar-toggler-icon "></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <li className="nav-item">
                {
                  <HashLink
                    smooth
                    to="/#whatBarakaDoesSection"
                    className="nav-links me-xxl-4 me-xl-4 me-lg-2 me-md-2 me-sm-2 me-2"
                  >
                    What Aagahii Does?
                  </HashLink>
                }
              </li>
              <li className="nav-item">
                <HashLink
                  smooth
                  to="/#coachingProgram"
                  className="nav-links me-xxl-4 me-xl-4 me-lg-2 me-md-2 me-sm-2 me-2"
                >
                  Coaching programme
                </HashLink>
              </li>
              <li className="nav-item">
                <HashLink
                  smooth
                  to="/#aboutSection"
                  className="nav-links me-xxl-4 me-xl-4 me-lg-2 me-md-2 me-sm-2 me-2"
                >
                  About me
                </HashLink>
              </li>
              <li className="nav-item">
                {
                  <HashLink
                    smooth
                    to="/#testimonialsSection"
                    className="nav-links me-xxl-4 me-xl-4 me-lg-2 me-md-2 me-sm-2 me-2"
                  >
                    Testimonials
                  </HashLink>
                }
              </li>
            </ul>

            <ul className="navbar-nav ms-auto d-flex align-items-center">
              <li className="nav-link p-0 me-2" style={{ fontSize: "-1px" }}>
                {authState ? (
                  <Button
                    className="nav-link"
                    onClick={() => {
                      logout();
                    }}
                    buttonStyle="btn--outline"
                  >
                    Logout
                  </Button>
                ) : (
                  <span>
                    <Button className="nav-link" buttonStyle="btn--outline">
                      <Link
                        className="nav-links-2 text-decoration-none text-white"
                        to="/login"
                      >
                        Login
                      </Link>
                    </Button>
                    {/* <Link to="/register" className="navbar-brand">Register</Link> */}
                  </span>
                )}
              </li>

              <li className="nav-item">
                {button && (
                  <Button
                    className="nav-link"
                    buttonStyle="btn--primary"
                    style={{ marginLeft: "7px" }}
                  >
                    Contact Us
                  </Button>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
  {
    authChange(authState);
  }
}

export default Navbar;
