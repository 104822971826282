import React, { Component } from 'react';
import { database, auth, storage } from '../../config/constants';
import DragAndDrop from '../DragAndDrop';
import '../../styles/Values.css';
import { Spinner } from '../Spinner';

var val = '';
class Value extends Component {
	constructor(props) {
		super(props);

		this.state = {
			values: '',
			isSubmitted: false,
			loading: true,
		};

		this.answerSelected = this.answerSelected.bind(this);
		this.questionSubmit = this.questionSubmit.bind(this);
	}

	answerSelected(event) {
		// this.setState({values: ''});
	}

	questionSubmit() {
		//Validate
		if (this.state.values.length < 10) return;
		database.ref('users/' + auth.currentUser.uid + '/ValueTest/').set({
			values: this.state.values,
		});
		this.props.history.push('/skill');
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		auth.onAuthStateChanged((currentUser) => {
			this.setState({ currentUser: currentUser || {} });

			if (currentUser) {
				// Init current user Refs
				this.userRef = database.ref('/users').child(currentUser.uid);
				this.skillRef = this.userRef.child('ValueSurvey');

				// Add user to users database if not exist
				this.userRef.once('value', (snapshot) => {
					const userData = snapshot.val();
					if (!userData) {
						this.userRef.set({ name: currentUser.displayName });
					}
				});
			} else {
				this.setState({ value: null });
			}
		});

		database
			.ref('users/' + auth.currentUser.uid)
			.once('value')
			.then((snapshot) => {
				let data = snapshot.val();
				if (data && 'ValueTest' in data) {
					if ('values' in data['ValueTest']) {
						this.setState({
							...this.state,
							isSubmitted: true,
							loading: false,
						});
					} else {
						this.setState({
							...this.state,
							loading: false,
						});
					}
				} else {
					this.setState({
						...this.state,
						loading: false,
					});
				}
				console.log(this.state);
			});
	}

	handleLanguage = (langValue) => {
		this.setState({ values: langValue });
		// console.log(langValue)
	};

	render() {
		if (this.state.loading) {
			return <Spinner />;
		}

		var questions;
		var user = auth.currentUser;

		if (user && !this.state.isSubmitted) {
			questions = (
				<div className=''>
					<div style={{ backgroundColor: '#FFCB77' }}>
						<div className='container'>
							<h1 className='text-white fw-bold py-5'>
								Evaluate Values!
							</h1>
							<p className='Hero-paragraph text-white pb-5 mb-0'>
								Choose the values that define and drive you (just drage and drop your top ten values)
							</p>
						</div>
					</div>

					<div
						className='alert alert-warning sticky-top'
						role='alert'
					>
						<div className='container'>
							Just drage and drop your top ten values
						</div>
					</div>

          {/* drag & drop part */}
					<div className='container'>
						<div className='row m-0'>
							<div className='col-12'>
								<DragAndDrop
									onSelectLanguage={this.handleLanguage}
								/>
							</div>
						</div>
						<button
							className='btn result-sub-btn mt-5 ms-4'
							type='submit'
							onClick={this.questionSubmit}
						>
							Submit Values
						</button>
					</div>
				</div>
			);
		} else if (this.state.isSubmitted) {
			questions = (
				<div style={{ pointerEvents: 'none', opacity: '0.4' }}>
					<h1> Text</h1>
					<h2> Already Submitted</h2>
					<input type='text' />
					<input type='password' />
					<button>Login</button>
				</div>
			);
		} else {
			questions = (
				<div className='App'>
					<div style={{ backgroundColor: '#EAEAF7' }}>
						<div className='container'>
							<h1 className='Hero-text'>
								Sign in first, <br /> then I can help.
							</h1>{' '}
							<br />
							<br />
							<p className='Hero-paragraph'>
								You need to login to be able to find your values
								and save your progress.
							</p>
							<button
								className='Hero-button'
								onClick={this.signIn}
							>
								Sign In
							</button>
						</div>
					</div>
				</div>
			);
		}
		return <div>{questions}</div>;
	}
}

export default Value;
