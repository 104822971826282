import React from "react";
import Image from "../../assets/images/OurMethods.png";
import Icon1 from "../../assets/images/activity.png";
import Icon2 from "../../assets/images/lock.png";

const OurMethods = () => {
  return (
    <div style={{ backgroundColor: "#FEFBE3" }}>
      <div className="w-75 row mx-auto pt-5 pb-md-5">
        <div
          className="col-lg-6  pt-md-5 text-secondary"
          id="whatBarakaDoesSection"
        >
          <p className="fs-5">Our Methodology</p>
          <p className="fs-2 fw-bolder" style={{ color: "#233B58" }}>
            What Aagahii does?
          </p>
          <p className="pb-4">
            The Aagahii methodology is inspired by the YouMap® method,{" "}
            <br />
            using techniques from humanistic integrative counseling.{" "}
          </p>
          <div className="d-flex mt-md-3">
            <img
              className="img-fluid me-3"
              style={{ width: "2.5rem", height: "2.5rem" }}
              src={Icon1}
              alt="icon"
            />
            <p className="w-75">
              Aagahii helps you discover the intersection of your Strengths, Values,
              Skills, and Interests. This is the secret sauce of career
              satisfaction.
            </p>
          </div>
          <div className="d-flex  mt-md-3">
            <img
              className="img-fluid me-3"
              style={{ width: "2.5rem", height: "2.5rem" }}
              src={Icon2}
              alt="icon"
            />
            <p className="w-75">
              Aagahii creates a safe space for you to examine the challenges you face
              in your career. With empathy and inner work, we help you discover
              the road ahead.
            </p>
          </div>
        </div>
        <div className="col-lg-4 mx-auto  pt-md-2 pb-5">
          <img
            className="img-fluid ms-md-5 ps-md-5 mb-md-3"
            src={Image}
            alt="Aagahii"
          />
        </div>
      </div>
    </div>
  );
};

export default OurMethods;
