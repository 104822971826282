import React from "react";
import FancyWords from "../common/FancyWords";

import { Link } from "react-router-dom";
import Image1 from "../../assets/images/evoInd1.png";
import Image2 from "../../assets/images/evoInd2.png";
import Image3 from "../../assets/images/evoInd3.png";
import Image4 from "../../assets/images/evoInd4.png";

const HomePage = () => {
  return (
    <>
      <div
        className="pt-5"
        style={{ backgroundColor: "#00B48C", color: "#fff" }}
      >
        <div className="w-75 row mx-auto pt-md-5 pb-md-5">
          <div className="col-lg-6 pt-md-5">
            <h1 className="fw-bold pt-md-3 pb-md-4">
              Searching for Meaning in Your Career? I can help.
            </h1>

            <p
              style={{ fontSize: "1.5rem" }}
              className="pb-md-4 pe-md-5 fw-lighter"
            >
              Discover the secret sauce of career success with Aagahii coaching.
            </p>
          </div>

          <div className="col-lg-4 mx-auto  pt-md-5 pb-5">
            <p
              className="homePageFancy"
              style={{
                fontFamily: "'Parisienne', cursive",
                color: "#FFCB77",
                fontSize: "7rem",
              }}
            >
              4 Steps
            </p>
          </div>
        </div>
      </div>

      {/* fancy Words */}
      <div style={{ backgroundColor: "#EFFFEE" }}>
        <FancyWords />
      </div>

      <div style={{ backgroundColor: "#233B58", color: "#fff" }}>
        <div className="w-75 row mx-auto pt-5 pb-md-5">
          <div className="col-lg-4 pt-md-2">
            <div className="d-flex align-items-center pt-md-3">
              <span className="hyphenStyle"></span>
              <p style={{ fontSize: "1.2rem" }}>Aagahii Evaluations</p>
            </div>

            <h1 className="fw-bold pt-md-3 pb-md-4">
              Complete the sections below to uncover your potential.
            </h1>
          </div>
        </div>

        <div className="w-75 row mx-auto pb-5 g-md-4 pt-4">
              <div className="col-lg-5 pb-5">
                <img
                  className="img-fluid mb-4"
                  style={{ width: "6rem" }}
                  src={Image1}
                  alt="Strengths"
                />
                <p className="fw-bold fs-5">Strengths</p>
                <p className="w-50 evalauationP">
                  Discover your top strengths by taking the Gallup StrengthsFinder Test.
                </p>
                <Link to="/strength">
                <button
                  className="btn btn-primary btn-md-lg btn-block rounded-end text-white fw-bold"
                  style={{ backgroundColor: "#FE8083" }}
                  type="button"
                  id="button-addon2"
                >
                  Evaluate Strengths
                </button>
                </Link>
              </div>

              <div className="col-lg-5  pb-5">
                <img
                  className="img-fluid mb-4"
                  style={{ width: "6rem" }}
                  src={Image2}
                  alt="Strengths"
                />
                <p className="fw-bold fs-5">Values</p>
                <p className="w-50 evalauationP">
                  Choose the values that define and drive you by clicking below.
                </p>
                <Link to="/value">
                <button
                  className="btn btn-primary btn-md-lg btn-block rounded-end text-black fw-bold"
                  style={{ backgroundColor: "#FFCB77" }}
                  type="button"
                  id="button-addon2"
                >
                  Evaluate Values
                </button>
                </Link>
              </div>
              <div className="col-lg-5  pb-5">
                <img
                  className="img-fluid mb-4"
                  style={{ width: "6rem" }}
                  src={Image3}
                  alt="Strengths"
                />
                <p className="fw-bold fs-5">Skills</p>
                <p className="w-50 evalauationP">
                  Identify the skills you possess by answering these questions.
                </p>
                <Link to="/skill">
                <button
                  className="btn btn-primary btn-md-lg btn-block rounded-end text-white fw-bold"
                  style={{ backgroundColor: "#00B48C" }}
                  type="button"
                  id="button-addon2"
                >
                  Evaluate Skills
                </button>
                </Link>
              </div>
              <div className="col-lg-5 pb-5">
                <img
                  className="img-fluid mb-4"
                  style={{ width: "6rem" }}
                  src={Image4}
                  alt="Strengths"
                />
                <p className="fw-bold fs-5">Interests</p>
                <p className="w-50 evalauationP">
                  Explore your personality, using scientifically crafted tools.
                </p>
                <Link to="/interest">
                <button
                  className="btn btn-primary btn-md-lg btn-block rounded-end text-white fw-bold"
                  style={{ backgroundColor: "#2377BF" }}
                  type="button"
                  id="button-addon2"
                >
                  Evaluate Interests
                </button>
                </Link>
              </div>
            </div>

      </div>
    </>
  );
};

export default HomePage;
