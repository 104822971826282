import React, { useRef, useState } from "react";
import "./Testimonials.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import Image1 from "../../assets/images/Abra.png";
import Image2 from "../../assets/images/Adam.png";
import Image3 from "../../assets/images/Kanza.png";
import Image4 from "../../assets/images/Tehmina.png";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";

// install Swiper modules
SwiperCore.use([Navigation]);

const CustomerTestimonals = () => {
  return (
    <div style={{ backgroundColor: "#FEFBE3" }} id="testimonialsSection">
      <div className="w-75 mx-auto pt-md-5 pb-5">
        <Swiper
          navigation={true}
          className="text-secondary mainSwiperCustomers"
        >
          <SwiperSlide className="row pt-5 mt-5">
            <div className="col-lg-6 mx-auto pt-md-5 pb-3 pb-md-5">
              <p className="fs-2 fw-bolder" style={{ color: "#233B58" }}>
                Customer Testimonials
              </p>
              <p className="pt-1 pb-2 pt-md-3 pb-md-3">
                "I am so pleased with the Aagahii method. The assessments
                and the personal attention I received was priceless."
              </p>
              <p className="text-black fs-5">Abra K.</p>
            </div>

            <div className="col-lg-4 mx-auto  pt-md-2 pb-5">
              <img
                className="img-fluid ms-md-5 ps-md-5 mb-md-3"
                src={Image1}
                alt="Aagahii"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide
            className="row pt-5 mt-5
          ms-3"
          >
            <div className="col-lg-6 mx-auto pt-md-5 pb-3 pb-md-5">
              <p className="fs-2 fw-bolder" style={{ color: "#233B58" }}>
                Customer Testimonials
              </p>
              <p className="pt-1 pb-2 pt-md-3 pb-md-3">
                "Wow what a great service, I love it! Aagahii has got
                everything I need to discover your potential and get ahead in
                your career."
              </p>
              <p className="text-black fs-5">Adam Q.</p>
            </div>

            <div className="col-lg-4 mx-auto  pt-md-2 pb-3 pb-md-5">
              <img
                className="img-fluid ms-md-5 ps-md-5 mb-md-3"
                src={Image2}
                alt="Aagahii"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide className="row pt-5 mt-5 ms-4">
            <div className="col-lg-6 mx-auto pt-md-5 pb-3 pb-md-5">
              <p className="fs-2 fw-bolder" style={{ color: "#233B58" }}>
                Customer Testimonials
              </p>
              <p className="pt-1 pb-2 pt-md-3 pb-md-3">
                "Aagahii is worth much more than I paid. I will recommend
                you to my colleagues."
              </p>
              <p className="text-black fs-5">Kanza S.</p>
            </div>

            <div className="col-lg-4 mx-auto  pt-md-2 pb-5">
              <img
                className="img-fluid ms-md-5 ps-md-5 mb-md-3"
                src={Image3}
                alt="Aagahii"
              />
            </div>
          </SwiperSlide>

          <SwiperSlide
            className="row pt-5 mt-5
          ms-3"
          >
            <div className="col-lg-6 mx-auto pt-md-5 pb-3 pb-md-5">
              <p className="fs-2 fw-bolder" style={{ color: "#233B58" }}>
                Customer Testimonials
              </p>
              <p className="pt-1 pb-2 pt-md-3 pb-md-3">
                "I have learnt so much from the genuineness and warmth of the
                coach."
              </p>
              <p className="text-black fs-5">Tehmina D.</p>
            </div>

            <div className="col-lg-4 mx-auto  pt-md-2 pb-3 pb-md-5">
              <img
                className="img-fluid ms-md-5 ps-md-5 mb-md-3"
                src={Image4}
                alt="Aagahii"
              />
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default CustomerTestimonals;
