import React, { Component } from "react";
import { database, auth, storage } from "../../config/constants";

import "../../styles/Skill.css";
import { Spinner } from "../Spinner";

class Strength extends Component {
  constructor(props) {
    super(props);

    this.state = {
      values: Array(5),
      isSubmitted: false,
      loading: true,
    };
    this.answerSelected = this.answerSelected.bind(this);
    this.questionSubmit = this.questionSubmit.bind(this);
  }

  answerSelected(event) {
    const values = [...document.getElementsByClassName("form-select")].map((tag) => tag.value)
    this.setState({
      ...this.state,
      values: values,
    })

  }

  questionSubmit() {
    //Validate
    this.state = {
      ...this.state,
      values: [...document.getElementsByClassName("form-select")].map(
        (tag) => tag.value
      ),
    };
    console.log(this.state);
    database.ref("users/" + auth.currentUser.uid + "/StrengthTest/").set({
      values: this.state.values,
    });
    this.setState({ isSubmitted: true });
    this.props.history.push("/value");
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    auth.onAuthStateChanged((currentUser) => {
      this.setState({ currentUser: currentUser || {} });

      if (currentUser) {
        // Init current user Refs
        this.userRef = database.ref("/users").child(currentUser.uid);
        this.skillRef = this.userRef.child("SkillSurvey");
        this.userStorageRef = storage.ref("/user-files").child(currentUser.uid);

        // Add user to users database if not exist
        this.userRef.once("value", (snapshot) => {
          const userData = snapshot.val();
          if (!userData) {
            this.userRef.set({ name: currentUser.displayName });
          }
        });
      } else {
        this.setState({ value: null });
      }
    });
    database
      .ref("users/" + auth.currentUser.uid)
      .once("value")
      .then((snapshot) => {
        let data = snapshot.val();
        if (data && "StrengthTest" in data) {
          if ("values" in data["StrengthTest"]) {
            this.setState({
              ...this.state,
              isSubmitted: true,
              loading: false,
            });
          } else {
            this.setState({
              ...this.state,
              loading: false,
            });
          }
        } else {
          this.setState({
            ...this.state,
            loading: false,
          });
        }
      });
  }

  render() {
    if (this.state.loading) {
      return <Spinner />;
    }

    var questions;
    var user = auth.currentUser;
    var dropdowns = [];
 
    const options = [
      "Achiever",
      "Activator",
      "Adaptability",
      "Analytical",
      "Arranger",
      "Belief",
      "Command",
      "Communication",
      "Competition",
      "Connectedness",
      "Consistency",
      "Context",
      "Deliberative",
      "Developer",
      "Discipline",
      "Empathy",
      "Focus",
      "Futuristic",
      "Harmony",
      "Ideation",
      "Includer",
      "Individualization",
      "Input",
      "Intellection",
      "Learner",
      "Maximizer",
      "Positivity",
      "Relator",
      "Responsibility",
      "Restorative",
      "Self",
      "Significance",
      "Strategic",
      "Woo",
    ];

    for (let i = 0; i < 5; i++) {
      dropdowns.push(
        <div key={i} className="col-lg-4 pe-md-4 allSectOptionFromSkill">
          <label style={{color:"#FE8083"}} htmlFor="validationCustom04" className="form-label pt-4 pt-md-5 fs-4">
            Strength #{i + 1}
          </label>
          <select
            className="form-select"
            id="validationCustom04"
            value={this.state.values[i]}
            onChange={this.answerSelected}
            defaultValue=""
            required
          >
            <option disabled value="">
              Choose...
            </option>
            {options.map((val, index) => (
              <option key={index} value={val} disabled={this.state.values.indexOf(val) != -1}>
                {val}
              </option>
            ))}
          </select>
          <div className="invalid-feedback">Please select a valid choice.</div>
        </div>
      );
    }

    if (user && !this.state.isSubmitted) {
      questions = (
        <div className="App">
          <div style={{ backgroundColor: "#FE8083", color: "#fff" }}>
            <div className="container pt-md-3 pb-md-4 ps-3">
              <h1 className="mb-5 pt-5 pb-md-3">Evaluate Strengths! </h1>

              <p className="Hero-paragraph pb-4">
                Discover your innate talents by taking the Gallup StrengthsFinder test.
              </p>

              <div className="d-md-flex pb-5">
              <div className="d-flex">
                <h2
                  className="pe-2 pe-md-3"
                  style={{
                    fontSize: "4rem",
                    fontFamily: "'Roboto', sans-serif",
                  }}
                >
                  1.
                </h2>
                <a
                href="https://my.gallup.com/_Home/RedeemAccessCode"
                  className="pe-4 btn pt-2 h-50 my-auto rounded-end text-black fw-bold"
                  style={{ backgroundColor: "#FFCB77" }}
                  type="button"
                  id="button-addon2"
                  target="_blank"
                >
                  Take the Gallup Test
                </a>
                </div>
                <p className=" pt-md-3 ps-md-4">
                  <span className="fw-bold d-block">
                    Access code + Username will be sent.
                  </span>
                  <span>can be found in your email</span>
                </p>
              </div>
            </div>
          </div>

          <div className="alert alert-danger sticky-top mb-0" role="alert">
            <div className="container">
              Enter your results obtained from Gallup Strength Test, and input
              below.
            </div>
          </div>

          <div style={{backgroundColor: "#FEE9EA"}}>
          <div className="container p-0">
          <div style={{color: "#FE8083"}} className="d-flex ms-3 ms-md-0 align-items-center pt-5">
                <h2
                  className="pe-2 pe-md-3"
                  style={{
                    fontSize: "4rem",
                    fontFamily: "'Roboto', sans-serif",
                    
                  }}
                >
                  2.
                </h2>
                <p className="fw-bold fs-3">Input the Strengths obtained from Gallup Test.</p>
              </div>
            <form className="row needs-validation">
              {dropdowns}
              <div className=" ps-5 ps-md-0 ms-1 ms-md-0 btn-md-lg pt-5 pb-5 mb-3 ">
                <button
                style={{ backgroundColor: "#FFCB77" }}
                  type="button"
                  className="btn btn-warning rounded fw-bold"
                  onClick={this.questionSubmit}
                >
                  Submit Gallup Results
                </button>
              </div>
            </form>
          </div>
          </div>
          
          <div className="container"></div>
        </div>
      );
    } else if (this.state.isSubmitted) {
      questions = (
        <div style={{ pointerEvents: "none", opacity: "0.4" }}>
          <h1> Text</h1>
          <h2> Already Submitted</h2>
          <input type="text" />
          <input type="password" />
          <button>Login</button>
        </div>
      );
    } else {
      questions = (
        <div className="App">
          <div style={{ backgroundColor: "#EAEAF7" }}>
            <div className="container">
              <h1 className="Hero-text">
                Sign in first, <br /> then I can help.
              </h1>{" "}
              <p className="Hero-paragraph">
                You need to login to be able to find your values and save your
                progress.
              </p>
              <button className="Hero-button" onClick={this.signIn}>
                Sign In
              </button>
            </div>
          </div>
        </div>
      );
    }
    return <div>{questions}</div>;
  }
}

export default Strength;
